import React from "react";
import { createRoot } from "react-dom/client";
import Recommender from './Recommender';

window.initializeComponent = function (config) {
  const {target, component, options = {}} = config;
  if (!target || !component) {
    return;
  }

  const rootElement = document.querySelector(target);
  if (!rootElement) {
    console.warn(`Unable to initialize component; Element "${target}" not found.`);
    return;
  }

  rootElement.innerHTML = '';
  switch (component) {
    case 'Recommender':
      initializeRecommenderComponent(target, options);
      break;
  }
}

window.initializeRecommenderComponent = function (rootElement, config) {
  const rootDomElement = document.querySelector(rootElement);
  if (rootDomElement) {
    const root = createRoot(rootDomElement);

    const defaultOptions = {
      title: 'Recommended Resources',
      headerAlignment: 'left',
      layout: '4-cols',
      theme: 'dark',
      navigationPlacement: 'topRight',
      overflow: false,
      showGreenElement: false,
    };
    const props = { ...defaultOptions, ...config };

    root.render(<Recommender {...props} />);
  } else {
    // Handle error
  }
}
